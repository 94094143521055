import { CopyIcon } from "@chakra-ui/icons";
import { Box, Text, Image, useDisclosure } from "@chakra-ui/react";
import { useSite } from "Site/SiteContext";
import { FloatingMenu } from "Site/Utilities/components/FloatingMenu";
import { Tooltip } from "Site/Utilities/components/cuttom-tooltip/CustomTooltip";
import { ID } from "Site/Utilities/constants/messagesConstants";
import { extractScriptTagsFromHtml } from "Site/Utilities/parseMarkup/extractScriptTags";
import extractHeadTags from "Site/Utilities/parseMarkup/extractTagsToHead";
import { FaMagic } from "react-icons/fa";
import { BsLink45Deg } from "react-icons/bs";
import {
  addStyleToWrapper,
  removeWrapperStyles,
} from "Site/Utilities/parseMarkup/handleGjsWrapperStyles";
import mergeHeadAndBody from "Site/Utilities/parseMarkup/mergeHeadAndBody";
import cssStringToObject from "Site/Utilities/parseMarkup/parseCssToJs";
import { pullStylesAndModifyHTML } from "Site/Utilities/parseMarkup/pullStylesAndModifyHTML";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { ReactComponent as EditImage } from "../../assets/svg/paint.svg";
import grapesjs from "grapesjs";
import "./custom-grapesjs.css"; //  Here we import the custom css for the panels

import { IconButton } from "@chakra-ui/react";

import "grapesjs/dist/css/grapes.min.css";
import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCommand,
  setBody,
  setCss,
  setHead,
  setLoading,
} from "store/editorSlice";
import {
  selectBody,
  selectCss,
  selectHead,
  selectIsUnsavedVersionViewing,
  selectJs,
  selectReRenderTemplate,
} from "store/selectors";
import { editorConfig } from "./gjsConfig";
import { ThunderboltIcon } from "Site/Utilities/icons/thunderboltIcon";
import { ListIcon } from "Site/Utilities/icons/listIcon";
import FileUploadModal from "../FileUploadModal";
import mediaService from "../mediaService/mediaService";
import imageIcon from "../../assets/svg/image-add.svg"

const MemoizedFloatingMenu = React.memo(FloatingMenu);
const GrapesEditor = () => {
  const editorRef = useRef(null);
  const editorInstance = useRef(null);
  const command = useSelector((state) => state.editor.command);
  const fetchedJs = useSelector(selectJs);
  const fetchedCss = useSelector(selectCss);
  const fetchedBody = useSelector(selectBody);
  const fetchedHead = useSelector(selectHead);
  const isUnsavedVersionViewing = useSelector(selectIsUnsavedVersionViewing);
  const copyIdStyles = useRef(null);

  const triggerReRender = useSelector(selectReRenderTemplate);
  const [isComponentFreshLoaded, setNotFresh] = useState(true);
  const {
    updateMarkupPartially,
    setcurrentPageVersionsId,
    currentPageVersionsId,
    versions,
    siteId
  } = useSite();
  const isEditorInUpdating = useRef(false);
  const isSetingNewVersion = useRef(false);
  const selectedComponentId = useRef(null);
  const isAiPanelOpened = useRef(false);
  const formatOfChange = useRef("element");
  const dispatch = useDispatch();
  const mousePosition = useRef({x: 0, y: 0});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {isOpen: isFileUploadOpen, onOpen: openFileUploadModal, onClose: closeFileUploadModal} = useDisclosure();

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assets, setAssets] = useState([]);


  const handleAssetSelection = (asset) => setSelectedAsset(asset)

  useEffect(() => {
    setUpGrapesEditor()
  }, [triggerReRender]);

  useEffect(() => {
    handleFetchImages()
  }, []);

  useEffect(updateEditorContent, [
    fetchedBody,
    fetchedJs,
    fetchedHead,
    fetchedCss,
    isUnsavedVersionViewing,
  ]);

  useEffect(() => {
    if (selectedAsset) {
      handleAsset()
      setSelectedAsset(null)
    }
  }, [selectedAsset])

  const replaceImage = (newImageUrl) => {
    const selectedComponent = editorInstance.current.getSelected();
    console.log(selectedComponent)

    if (selectedComponent && selectedComponent.is('image')) {
      selectedComponent.set('src', newImageUrl);
    }
  }

  const handleFetchImages = async () => {
    const images = await mediaService.fetchImages(siteId);
    if (images) {
      setAssets(images);
    }
  };

  const handleDeleteImage = async (id) => {
    const success = await mediaService.deleteImage(id, siteId);
    if (success) {
      await handleFetchImages();
    }
  };

  const handleUploadFile = async (file) => {
    const result = await mediaService.uploadFile(file, siteId);
    if (result) {
      await handleFetchImages();
    }
  };
  const handleAsset = () => {
    replaceImage(selectedAsset.src)
  }

  /**
   * Initializes the GrapesJS editor with necessary configurations.
   */
  function setUpGrapesEditor() {
    const editor = grapesjs.init({
      ...editorConfig,
      container: editorRef.current,
      assetManager: {
        assets: assets,
        custom: {
          open: (props) => {
          },
          close: () => {
          }
        },
      },
      blockManager: {
        blocks: [
          {
            id: 'image-block',
            label: '<svg width="60" height="60" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '    <path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="white"/>\n' +
                '    <path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="white"/>\n' +
                '    <path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="white"/>\n' +
                '</svg>',
            category: 'Basic',
            activate: true,
            select: true,
            content: { type: 'image', style: { width: '100%', height: 'auto' } },
            attributes: { class: 'custom-image-block' }
          },
        ],
      },
      commands: {
        defaults: [
          {
            id: "tlb-request-change",
            run() {
              onOpen();
            },
          },
          {
            id: "ai-request-variation",
            run() {
              isAiPanelOpened.current = !isAiPanelOpened.current;
              const askAiSelect = document.getElementById("ask-ai-select");
              if (askAiSelect) {
                const aiPopup = document.createElement("div");
                aiPopup.className = "ai-popup";
                aiPopup.style.display = isAiPanelOpened.current
                    ? "flex"
                    : "none";
                aiPopup.innerHTML = `
                  <div style="display: flex; gap: 5px">
                    ${ThunderboltIcon}<button id="change-element-btn"> Change element</button>
                  </div>
                  <div style="display: flex; gap: 5px">
                    ${ListIcon}<button id="generate-text-btn"> Generate text</button>
                  </div>
                `;

                askAiSelect.insertAdjacentElement("afterend", aiPopup);

                const changeElementBtn =
                    document.getElementById("change-element-btn");
                const generateTextBtn =
                    document.getElementById("generate-text-btn");

                if (changeElementBtn) {
                  formatOfChange.current = "element";
                  changeElementBtn.onclick = function () {
                    setTimeout(() => {
                      editor.runCommand("tlb-request-change");
                      aiPopup.style.display = "none"
                    })
                  };
                }

                if (generateTextBtn) {
                  formatOfChange.current = "text";
                  generateTextBtn.onclick = function () {
                    setTimeout(() => {
                      editor.runCommand("tlb-request-change");
                      aiPopup.style.display = "none"
                    })
                  };
                }

                document.addEventListener('click', function closePopup(e) {
                  if (e.target instanceof Node && !aiPopup.contains(e.target) && 
                      !e.target.closest('#ask-ai-select') && 
                      e.target !== askAiSelect) {
                    aiPopup.style.display = 'none';
                    isAiPanelOpened.current = false;
                    document.removeEventListener('click', closePopup);
                  }
                  
                  if (e.target instanceof Element && e.target.id) {
                    if (e.target.id !== 'generate-text-btn') {
                      onClose();
                    }
                    
                    if (e.target.id !== 'change-element-btn') {
                      onClose();
                    }
                  }
                });
              }
              const AskAiButton = document.querySelector(".magic-block");

              if (AskAiButton instanceof HTMLElement) {
                AskAiButton.style.backgroundColor = isAiPanelOpened.current ? "#61dafb" : "none";
              }

            },          
          },
          {
            id: "attach-link",
            run(editor, sender, options) {
              onClose()
              const selectedComponent = editor.getSelected();

              const parentComponent = selectedComponent.parent();
              let existingUrl = '';
              
              if (parentComponent && parentComponent.attributes.tagName === 'a') {
                existingUrl = parentComponent.attributes.attributes.href || '';
              } else if (selectedComponent.attributes.tagName === 'a') {
                existingUrl = selectedComponent.getAttributes().href || '';
              }

              const popupContent = `
                <div style="position: relative;">
                  <button id="close-popup" style="position: absolute; top: -20px; right: -13px; background-color: transparent; color: #333; border: none; font-size: 18px; cursor: pointer;">×</button>
                  <input type="text" id="url-input" placeholder="Enter URL for the link" value="${existingUrl}" style="width: 100%; padding: 5px; border: 1px solid #ccc; border-radius: 4px;">
                  <button id="apply-url" style="margin-top: 10px; background-color: #3c8dff; color: white; border: none; padding: 8px 16px; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; border-radius: 4px; cursor: pointer;">Apply</button>
                  <button id="delete-url" style="margin-top: 10px; margin-left: 10px; background-color: #ff3c3c; color: white; border: none; padding: 8px 16px; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; border-radius: 4px; cursor: pointer;">Delete</button>
                </div>
              `;
        
              // Close previous popup if exists
              const existingPopup = document.querySelector('.attach-link-popup');
              if (existingPopup) {
                document.body.removeChild(existingPopup);
              }

              // Create new popup
              const popup = document.createElement('div');
              popup.innerHTML = popupContent;
              popup.style.position = 'fixed';
              popup.style.backgroundColor = '#fff';
              popup.style.border = '1px solid #ccc';
              popup.style.zIndex = '1000';
              popup.style.padding = '20px';
              popup.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.1)';
              popup.classList.add('attach-link-popup');

              // Get mouse coordinates
              const mouseX = options.event.clientX;
              const mouseY = options.event.clientY;

              // Set initial popup position
              popup.style.left = `${mouseX}px`;
              popup.style.top = `${mouseY}px`;

              document.body.appendChild(popup);

              // Check and adjust popup position
              const rect = popup.getBoundingClientRect();
              const viewportWidth = window.innerWidth;
              const viewportHeight = window.innerHeight;

              if (rect.right > viewportWidth) {
                popup.style.left = `${viewportWidth - rect.width}px`;
              }
              if (rect.bottom > viewportHeight) {
                popup.style.top = `${viewportHeight - rect.height}px`;
              }

              // Apply button handler
              document.getElementById('apply-url').addEventListener('click', () => {
                const urlInput = document.getElementById('url-input');
                if (urlInput && urlInput.value) {
                  wrapElementInLink(urlInput.value);
                }
                if (document.body.contains(popup)) {
                  document.body.removeChild(popup);
                }
              });

              document.getElementById('delete-url').addEventListener('click', () => {
                const urlInput = document.getElementById('url-input');
                if (urlInput && urlInput.value) {
                  unwrapElementFromLink();
                }
                if (document.body.contains(popup)) {
                  document.body.removeChild(popup);
                }
              });

              // Close button handler
              document.getElementById('close-popup').addEventListener('click', () => {
                if (document.body.contains(popup)) {
                  document.body.removeChild(popup);
                }
              });

              // Close popup when clicking outside
              const closePopup = (event) => {
                if (!popup.contains(event.target)) {
                  if (document.body.contains(popup)) {
                    document.body.removeChild(popup);
                  }
                  document.removeEventListener('mousedown', closePopup);
                }
              };

              // Add handler with a small delay to avoid instant closing
              setTimeout(() => {
                document.addEventListener('click', closePopup);
                document.querySelector('.gjs-toolbar').addEventListener('click', closePopup);
              }, 100);

              // Close popup when pressing Escape key
              const handleEscapeKey = (event) => {
                if (event.key === 'Escape') {
                  if (document.body.contains(popup)) {
                    document.body.removeChild(popup);
                  }
                  document.removeEventListener('keydown', handleEscapeKey);
                }
              };

              document.addEventListener('keydown', handleEscapeKey);
            },
          },
          {
            id: "edit-text",
            run(editor) {
              startTextEditingIfApplicable(editor);
            },
          },
          {
            id: "open-text-editor",
            run(editor) {
              onClose(); // closing the ai request menu
              openFileUploadModal();
              editor.getSelected().trigger("active");
            },
          },
          {
            id: "copy-id",
            run(editor) {
              navigator.clipboard.writeText(selectedComponentId.current);
              document.querySelector(".copy-id .chakra-text").style.background =
                "green";
              setTimeout(() => {
                document.querySelector(
                  ".copy-id .chakra-text"
                ).style.background = "#3C8DFF";
              }, 1000);
            },
          },
          {
            id: "open-custom-modal",
            run() {
              openFileUploadModal();
            },
          }
        ],
      },
      storageManager: {
        id: "gjs-",
        type: "local",
        autosave: true,
        autoload: true,
        stepsBeforeSave: 1,
      },
      canvasCss: `
        .gjs-selected {
        outline: 2px solid #3C8DFF !important;
        border-radius: 3px;
      }
    `,
    });

    editorInstance.current = editor;

    editor.on("load", () => {
      isSetingNewVersion.current = false;
    });

    /**
     * Handles the update event of the editor.
     */
    editor.on("update", () => {
      localStorage.removeItem("gjsProject");

      if (isSetingNewVersion.current) {
        isSetingNewVersion.current = false;
        return;
      }

      isEditorInUpdating.current = true;

      const formattedStyles = cssStringToObject(
        editorInstance.current.getCss({ avoidProtected: true })
      );
      const clearedStyles = removeWrapperStyles(formattedStyles);
      const { headTags, cleanedHtml } = extractHeadTags(
        editorInstance.current.getHtml()
      );
      const { htmlWithoutScripts } = extractScriptTagsFromHtml(cleanedHtml);

      dispatch(setHead(headTags));
      dispatch(setBody(htmlWithoutScripts));
      dispatch(setCss(clearedStyles));

      dispatch(setLoading(true));

      updateMarkupPartially("head", headTags);
      updateMarkupPartially("body", htmlWithoutScripts);
      updateMarkupPartially("css", clearedStyles);
    });

    editor.on("component:selected", () => {
      const selectedComponent = editor.getSelected();
      selectedComponentId.current = selectedComponent.ccid;
      const contentType = selectedComponent.attributes.type;
      let icon = false;
      copyIdStyles.current = { marginRight: "85px" };
      if (contentType === "text") {
        copyIdStyles.current = { marginRight: "100px" };
        icon = <EditIcon />;
      } else if (contentType === "image") {

        const imageEl = selectedComponent.view.el;
        imageEl.addEventListener('dblclick', () => {
          editor.runCommand('open-custom-modal');
        });

        copyIdStyles.current = {marginRight: "100px"};
        icon = <EditImage />;
      }

      editor.on('block:drag:stop', (block) => {
        const addedComponent = editor.getSelected();

        if (addedComponent && addedComponent.is('image')) {
          openFileUploadModal();
        }
      });


      const defaultToolbar = selectedComponent.get("toolbar").filter((item) => {
        const excludedCommands = [
          "move",
          "clone",
          "open-text-editor",
          "tlb-request-change",
        ];
        return (
          typeof item?.command !== "string" ||
          !excludedCommands.some((cmd) => item.command.includes(cmd))
        );
      });

      const combinedToolbar = [
        {
          type: "ask-ai",
          label: renderToStaticMarkup(
            <div id="ask-ai-select" className="magic-block">
              <FaMagic />
              <Box fontSize="xs">
                {" "}
                Ask AI
              </Box>
            </div>
          ),
          title: "request change",
          command: "ai-request-variation",
        },
        ((selectedComponent.attributes.type === 'image'
        || selectedComponent.attributes.type === 'text'
        ) && {
          type: "separator",
          label: renderToStaticMarkup(
            <div className="gjs-toolbar-divider"></div>
          ),
          command: "",
        }),
        (selectedComponent.attributes.type === 'image' && {
          type: "link",
          label: renderToStaticMarkup(
            <BsLink45Deg style={{ width: "18px", height: "18px" }} />
          ),
          command: "attach-link",
        }),
        ...(icon
          ? [
            {
              label: renderToStaticMarkup(icon),
              title: "edit",
              command: "open-text-editor",
            },
          ]
          : []),
        (selectedComponent.attributes.type === 'text' && {
          type: "link",
          label: renderToStaticMarkup(
            <BsLink45Deg style={{ width: "18px", height: "18px" }} />
          ),
          command: "attach-link",
        }),
        {
          type: "separator",
          label: renderToStaticMarkup(
            <div className="gjs-toolbar-divider"></div>
          ),
          command: "",
        },
        {
          type: "copy-id",
          label: renderToStaticMarkup(
            <Box className="copy-id" style={copyIdStyles.current}>
              <Text fontSize="xs">{ID + selectedComponentId.current}</Text>
              <IconButton
                size="xs"
                leftIcon={<CopyIcon fontSize="xs" />}
                onClick={() => console.log(ID + selectedComponentId.current)}
              >
                Copy
              </IconButton>
            </Box>
          ),
          title: ID + selectedComponentId.current,
          command: "copy-id",
        },
        ...defaultToolbar.filter(
          (element) =>
            !["separator", "copy-id", "link", "edit-text", "ask-ai"].includes(
              element.type
            )
        ),
      ];

      const updatedToolbar = combinedToolbar.map((item, index) => {
        if (item.label && item.command !== "") {
          let tooltipLabel = "";
          if (item.command === "open-text-editor") {
            tooltipLabel = "Text Editor";
          }
          if (item.command === "attach-link") {
            tooltipLabel = "Attach Link";
          }
          if (item.command === "ai-request-variation") {
            tooltipLabel = "Send Request";
          } else if (item.command === "tlb-delete") {
            tooltipLabel = "Delete";
          } else if (item.title) {
            tooltipLabel =
              item.title.charAt(0).toUpperCase() + item.title.slice(1);
          }

          const tooltipElement = 
          item.command === "copy-id" 
          ? (
            <span dangerouslySetInnerHTML={{ __html: item.label }}></span>

          )
          : (
            <Tooltip label={tooltipLabel || "Go to parent"} placement="top">
              <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
            </Tooltip>
          );
          item.label = renderToStaticMarkup(tooltipElement);
        }
        return item;
      });

      selectedComponent.set({
        toolbar: updatedToolbar,
      });
      adjustToolbarPosition();
    });

    return () => {
      editor.destroy();
    };
  }

  /**
   * Adjusts the vertical position of the toolbar items by moving them 20 pixels upwards.
   * This function selects the toolbar items container and modifies its top style property.
   */
  function adjustToolbarPosition() {
    setTimeout(() => {
      const toolbarItems = document.querySelector('.gjs-toolbar-items').parentElement;
      if (toolbarItems && toolbarItems instanceof HTMLElement) {
        
        // Set animation before adjusting top
        toolbarItems.style.transition = 'top 0.1s ease-out';
        
        // Get the current top value
        const currentTop = parseInt(toolbarItems.style.top) || 0;
        
        // Subtract 20 from the current top value only if it's less than 0
        if (currentTop < 0) {
          toolbarItems.style.top = `0`;
          toolbarItems.style.top = `-40px`;
        }
        if (currentTop === 0) {
          toolbarItems.style.top = `0`;
          toolbarItems.style.top = `-10px`;
        }
      }
    }, 0);
  }
  

  /**
   * Updates the editor's content when fetched data changes.
   */
  function updateEditorContent() {
    if (!editorInstance.current || !fetchedBody || !fetchedCss || !fetchedHead)
      return;
    if (isUnsavedVersionViewing) return;

    if (isEditorInUpdating.current) {
      isEditorInUpdating.current = false;
      return;
    }

    isSetingNewVersion.current = true;

    const grapesjsBodyWithAllTags = mergeHeadAndBody(fetchedHead, fetchedBody);

    const [styleTagStyles, styleAttrStyles, modifiedMarkup] =
      pullStylesAndModifyHTML(grapesjsBodyWithAllTags);

    if (styleTagStyles)
      editorInstance.current.setStyle(cssStringToObject(styleTagStyles));

    if (styleAttrStyles) applyAttrCssRulesToGrapesJS(styleAttrStyles);

    const cssToWiewInGrapesjs = addStyleToWrapper(fetchedCss);

    editorInstance.current.setStyle({});
    editorInstance.current.setComponents(modifiedMarkup);
    editorInstance.current.setStyle(cssStringToObject(cssToWiewInGrapesjs));

    if (isComponentFreshLoaded && fetchedJs) {
      setNotFresh(false);
      addScriptsToEditor(fetchedJs);
      editorInstance.current.UndoManager.clear();
    }
  }

  useEffect(() => {
    if (command && editorInstance.current) {
      handleCommand(command);
      dispatch(clearCommand());
    }
  }, [command, dispatch]);

  const restrictedElements = [
    "BUTTON",
    "INPUT",
    "TEXTAREA",
    "SELECT",
    "OPTION",
    "HTML",
    "HEAD",
    "META",
    "LINK",
    "SCRIPT",
    "STYLE",
    "TITLE",
    "IFRAME",
    "DIV",
    "SPAN",
    "AUDIO",
    "VIDEO",
    "ADDRESS",
    "BLOCKQUOTE",
    "CODE",
    "PRE",
    "TABLE",
    "TR",
    "TD",
    "FORM",
    "FIELDSET",
    "LABEL",
  ];
function wrapElementInLink(url) {
  const editor = editorInstance.current;
  const selectedComponent = editor.getSelected();

  const tagName = selectedComponent.get("tagName").toUpperCase();

  if (restrictedElements.includes(tagName)) {
      console.log(`Cannot wrap element of type ${tagName} in a link.`);
      return;
  }

  const parentComponent = selectedComponent.parent();

  if (!parentComponent) {
      console.log("Parent component not found.");
      return;
  }

  // Add https:// to the URL if it doesn't start with http:// or https://
  const fullUrl = url === '' ? '' : (url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`);

  // Check if the parent is already an 'a' tag
  if (parentComponent.get("tagName").toLowerCase() === 'a') {
      // Update the href attribute of the parent 'a' tag
      parentComponent.addAttributes({ href: fullUrl });
      editor.store();
      console.log(`Updated link URL to: ${fullUrl} and saved changes.`);
      return;
  }

  // Clone the selected component
  const clonedComponent = selectedComponent.clone();

  // Create a new link component
  const linkComponent = editor.DomComponents.addComponent({
      tagName: "a",
      attributes: { href: fullUrl, target: "_blank", rel: "noopener noreferrer" },
      components: [clonedComponent],
  });

  // Replace the selected component with the new link component
  selectedComponent.replaceWith(linkComponent);

  editor.store();

  console.log(`Wrapped element in link: ${fullUrl} and saved changes.`);
}

function unwrapElementFromLink() {
    const editor = editorInstance.current;
    const selectedComponent = editor.getSelected();
  
    if (!selectedComponent) {
      console.log("No component selected.");
      return;
    }
  
    const parentComponent = selectedComponent.parent();
  
    // Check if the parent component is an anchor tag
    if (!parentComponent || parentComponent.get("tagName").toUpperCase() !== "A") return;
  
    const grandParentComponent = parentComponent.parent();

    if (!grandParentComponent) {
      console.log("Grandparent component not found.");
      return;
    }

    // Get all child components of the anchor tag
    const childComponents = parentComponent.components().models;

    // Remove the anchor tag and add its children to the grandparent
    grandParentComponent.append(childComponents);
    parentComponent.remove();

    editor.store();
  }
  function startTextEditingIfApplicable(editor) {
    const component = editor.getSelected();
    const isTextEditable = component && component.is("text");

    if (isTextEditable) {
      editor.getSelected().trigger("active");
    }
  }

  /**
   * Applies CSS rules to GrapesJS editor based on the provided styles array.
   * @param {Object[]} stylesArray - Array of objects containing elementSelector and cssStyle.
   * @param {Object} editor - Instance of GrapesJS editor.
   */
  function applyAttrCssRulesToGrapesJS(stylesArray) {
    const cssRules = stylesArray
      .map((styleObj) => {
        return `${styleObj.elementSelector} { ${styleObj.cssStyle} }`;
      })
      .join("\n");

    editorInstance.current.CssComposer.add(cssRules);
  }

  /**
   * Handles editor commands like undo and redo.
   * @param {string} command - The command to execute.
   */
  function handleCommand(command) {
    if (command === "undo") {
      editorInstance.current.UndoManager.undo();
    } else if (command === "redo") {
      editorInstance.current.UndoManager.redo();
    }
  }

  /**
   * Adds a script to the editor.
   * @param {string} content - The script content to add.
   */
  function addScriptToEditor(content) {
    editorInstance.current.addComponents({
      tagName: "script",
      type: "script",
      content: content,
    });
  }

  /**
   * Adds multiple scripts to the editor.
   * @param {Array|string} scripts - The scripts to add.
   */
  function addScriptsToEditor(scripts) {
    if (Array.isArray(scripts)) {
      scripts.forEach((content) => addScriptToEditor(content));
    } else {
      addScriptToEditor(scripts);
    }
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      mousePosition.current = {
        x: event.clientX,
        y: event.clientY,
      };
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    if (isUnsavedVersionViewing) return;

    const buttonsMenuWrapper = document.querySelector(".buttons-menu-wrapper");

    if (buttonsMenuWrapper) {
      const sibling = buttonsMenuWrapper.nextElementSibling;

      if (sibling) {
        sibling.style.height = "100%";
      }
    }
  }, [isUnsavedVersionViewing]);

  useEffect(() => {
    setcurrentPageVersionsId(versions[0].SiteVersionsId);
  }, [versions]);

  return (
    <>
      <Box
        visibility={isUnsavedVersionViewing ? "hidden" : "visible"}
        position={isUnsavedVersionViewing ? "fixed" : "relative"}
        zIndex={isUnsavedVersionViewing ? "-100000" : "0"}
        ref={editorRef}
      >
        {isOpen && (
          <MemoizedFloatingMenu
            selectedComponentId={selectedComponentId.current}
            onClose={onClose}
            formatOfChange={formatOfChange.current}
            mousePosition={mousePosition.current}
          ></MemoizedFloatingMenu>
        )}
      </Box>
      <FileUploadModal
          handleAssetSelection={handleAssetSelection}
          siteId={siteId}
          assets={assets}
          setAssets={setAssets}
          uploadFile={handleUploadFile}
          isOpen={isFileUploadOpen}
          onClose={closeFileUploadModal}
          onOpen={openFileUploadModal}
          handleDeleteImage={handleDeleteImage}

      />
    </>
  );
};

export default GrapesEditor;
